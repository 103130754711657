import _module from 'module.js'
import _ from 'lodash'
import BaseController from 'classes/baseController'
import jrnParser from 'classes/jrnParser'
import { version, buildTime } from 'environments/version'
import { FEATURE_FLAGS } from '../../services/featuresService'

export default class SettingsController extends BaseController {
  constructor(
    $rootScope,
    $stateParams,
    $state,
    $location,
    portalApi,
    gettextCatalog,
    Settings,
    changeExtensionDigits,
    errorService,
    userService,
    $analytics,
    LIST_LIMIT_DEFAULT,
    $q,
    portalUtil,
    globalState,
    navigation,
    featuresService,
    deviceFeaturesService,
    awsSecretsPopup,
    $timeout,
    Did
  ) {
    'ngInject'
    super(
      $state,
      $stateParams,
      Settings,
      portalApi,
      userService,
      gettextCatalog,
      portalUtil,
      errorService,
      globalState,
      navigation,
      'root.nav.pbx.settings.general',
      'noList'
    )

    this.jrn = jrnParser
    this.$location = $location
    this.changeExtensionDigits = changeExtensionDigits
    this.$analytics = $analytics
    this.LIST_LIMIT_DEFAULT = LIST_LIMIT_DEFAULT
    this.$q = $q
    this.$rootScope = $rootScope
    this.$timeout = $timeout
    this.featuresService = featuresService
    this.deviceFeatureService = deviceFeaturesService
    this.awsSecretsPopup = awsSecretsPopup
    this.Did = Did
    this.e911V3LocationsParams = { useForEmergencyServicesOrAccountDefault: true }
    this.locationsApi = portalApi.location.accounts(globalState.selectedPbxLmiAccountKey).locations
    this.emergencySettingsApi = portalApi.location.accounts(globalState.selectedPbxLmiAccountKey).emergencySettings
    this.e911V3AccountDefaultLocation = null

    this.regionChanged = false
    this.supportedRegions = globalState.supportedRegions

    this.isEmergencyLocationsTab = $state.current.name === 'root.nav.pbx.settings.location'

    if ($state.current.name === 'root.nav.pbx.settings.hidden' && !globalState.isPlatform) {
      $state.go('root.nav.pbx.settings.general', $stateParams)
    }

    this.region = globalState.supportedRegions.find(
      ({ code }) => code === globalState.selectedPbx.region
    )
    this.supportedLanguages = globalState.supportedLanguages
    this.selectedPbxUUID = globalState.selectedPbxUUID
    this.corporateDirectory = globalState.selectedPbx.defaultCorporateDirectory
    this.isWhiteLabel = globalState.isWhiteLabel
    this.isE911Enabled = globalState.isE911Enabled
    this.isE911V3Enabled = globalState.isE911V3Enabled

    this.version = version
    this.buildTime = buildTime

    this.pstnOutboundEnabled = globalState._selectedPbx.featureFlags['pbx.pstn.outbound']
    this.isStoreIncomingFaxes = globalState.selectedPbx.storeIncomingFaxes
    this.virtualFaxMachineStorageEnabled = globalState.isVirtualFaxMachineStorageEnabled

    const i18nLang = ['nl', 'de', 'it', 'fr', 'de', 'pt', 'es'].filter((lang) => lang === globalState.currentLanguage.langCode.split('-')[0])
    const defaultLang = i18nLang.length > 0 ? `${i18nLang[0]}/` : ''
    this.jiveStorageExplanationLink = `https://support.goto.com/${defaultLang}connect/help/what-are-my-call-recording-storage-options`

    this.awsStorageExplanation = this.gettextCatalog.getString(
      'Recording (WAV/WAV49) will be stored in your own AWS account.'
    )
    this.awsAccessKeyIdHeader = this.gettextCatalog.getString('Access Key ID: ')

    this.cboxStorageName = this.isWhiteLabel
      ? this.gettextCatalog.getString('HVoice Storage')
      : this.gettextCatalog.getString('GoToConnect Storage')

    this.jbcLabel = this.isWhiteLabel
      ? this.gettextCatalog.getString('Business Continuity')
      : this.gettextCatalog.getString('JBC')
    this.usingJbcOverwrite = this.isWhiteLabel
      ? this.gettextCatalog.getString('Disabled - Using Business Continuity')
      : this.gettextCatalog.getString('Disabled - Using JBC')

    this.jbcEnabled = false
    this.loadData()

    this.cboxStorageData = [
      {
        label: this.cboxStorageName,
        value: true
      },
      {
        label: this.gettextCatalog.getString('Custom AWS Storage'),
        value: false
      }
    ]
    this.cboxStorageSelector = this.cboxStorageData[0]

    this.cdpRadioData = [
      {
        label: this.gettextCatalog.getString('Enabled'),
        value: true
      },
      {
        label: this.gettextCatalog.getString('Use Device Default'),
        value: 'default'
      },
      {
        label: this.gettextCatalog.getString('Disabled'),
        value: false
      }
    ]

    this.lldpRadioData = [
      {
        label: this.gettextCatalog.getString('Enabled'),
        value: true
      },
      {
        label: this.gettextCatalog.getString('Use Device Default'),
        value: 'default'
      },
      {
        label: this.gettextCatalog.getString('Disabled'),
        value: false
      }
    ]

    this.supportedDeviceFeatures = {}
    this.appliedFeatureConfigurations = {}
    this.editableDeviceFeatureConfigurations = {}
    this.baseEditableDeviceFeatureConfigurations = {}
    this.updateAppliedFeatures()
    this.updateSupportedOrganizationFeatures()
    this.updatePSTNOutboundFeatures()

    this.portalApi
      .customEmergencyNumbers(this.globalState.selectedPbxUUID)
      .getList()
      .then(response => {
        _.forEach(response, number => {
          number.text = number.number
        })
        this.data.customEmergencyNumbers = response
      })

    // mark these strings for translation.
    this.gettextCatalog.getString('Australia')
    this.gettextCatalog.getString('Hungary')
    this.gettextCatalog.getString('Brazil')
    this.gettextCatalog.getString('Canada')
    this.gettextCatalog.getString('Germany')
    this.gettextCatalog.getString('Mexico')
    this.gettextCatalog.getString('United Kingdom')
    this.gettextCatalog.getString('United States')
    this.gettextCatalog.getString('Spain')
    this.gettextCatalog.getString('France')
    this.gettextCatalog.getString('Romania')
    this.gettextCatalog.getString('Ireland')
    this.gettextCatalog.getString('New Zealand')
    this.gettextCatalog.getString('Italy')
    this.gettextCatalog.getString('Denmark')
    this.gettextCatalog.getString('Austria')
    this.gettextCatalog.getString('Belgium')
    this.gettextCatalog.getString('Croatia')
    this.gettextCatalog.getString('Poland')
    this.gettextCatalog.getString('Sweden')
    this.gettextCatalog.getString('Singapore')
  }

  async loadData() {
    try {
      await this.data.get()

      await this.handleCallerIdWarnings()

      if (this.isE911V3Enabled) {
        await this.getE911V3AccountDefaultLocation()
      }

      await this.getAccountEmergencySettings()

      if (!this.data.useCboxStorage) {
        this.cboxStorageSelector = this.cboxStorageData[1]
      }

      this.shouldShowAreaCodeField = this.portalUtil.regionHasAreaCodes(this.data.region)
      this.shouldShowPortOutPassCode = this.globalState.isUserSuperAdmin || this.globalState.isPlatformAdmin
      if (this.shouldShowPortOutPassCode) {
        await this.getPortOutPasscode()
      }
      this.ready = true
      this.initial = false
      this.$timeout(() => this.$rootScope.$apply())
    } catch (err) {
      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString(
            'Data retrieval failed, please retry.'
          )
          : errorMessage
      )
      this.ready = true
      this.initial = false
      this.$timeout(() => this.$rootScope.$apply())
      throw err
    }
  }

  async handleCallerIdWarnings() {
    this.showPbxCallerIdWarning = false

    if (this.data.defaultOutboundDid) {
      const id = jrnParser.parse(this.data.defaultOutboundDid).getResources()[1]
      const outboundDid = await new this.Did(id).get()
      if (!outboundDid.outboundRoutable) this.showPbxCallerIdWarning = true
    } else {
      this.showPbxCallerIdWarning = true
    }
  }

  async getE911V3AccountDefaultLocation() {
    this.e911V3AccountDefaultLocation = null
    try {
      const defaultLocation = await this.locationsApi.call(this, 'accountDefault').get({ cache: true })
      if (defaultLocation) {
        this.e911V3AccountDefaultLocation = defaultLocation//defaultLocation.key
      }
    } catch (err) {
      const linkUrl = `${this.$location.absUrl().split(this.selectedPbx.domain)[0]}${this.selectedPbx.domain}/settings/location`
      if (!this.isEmergencyLocationsTab) {
        this.portalUtil.showErrorAlert(this.gettextCatalog.getString(`There is no default location set. Please ensure you have registered an emergency location in the  <a href="${linkUrl}" target="_self">emergency locations settings tab</a>`))
      } else {
        const errorMessage = this.errorService.getErrorMessage(err)
        console.error(errorMessage)
      }
    }
  }

  async getAccountEmergencySettings() {
    try {
      const emergencySettings = await this.emergencySettingsApi.call(this).get({ cache: true })
      if (emergencySettings && this.data) {
        this.data.emergencyCallAlertsEnabled = emergencySettings.emergencyCallAlertsEnabled
        this.data.emergencyAlertEmails = emergencySettings.emergencyAlertEmails
        this.data.deviceMovementAlertsEnabled = emergencySettings.deviceMovementAlertsEnabled
        this.data.accountDefaultLocation = emergencySettings.accountDefaultLocation
      }
    } catch (err) {
      console.error(this.errorService.getErrorMessage(err))
    }
  }

  async updateEmergencySettings() {
    try {
      const payload = this.emergencySettingsApi.call(this)
      payload.emergencyCallAlertsEnabled = this.data.emergencyCallAlertsEnabled,
        payload.emergencyAlertEmails = this.data.emergencyAlertEmails,
        payload.deviceMovementAlertsEnabled = this.data.deviceMovementAlertsEnabled,
        payload.accountDefaultLocation = this.isE911V3Enabled && this.e911V3AccountDefaultLocation ? this.e911V3AccountDefaultLocation.key : null
      await payload.put()
    } catch (err) {
      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString('Save emergency settings failed, please retry.')
          : errorMessage
      )
      this.ready = true
      throw err
    }
  }

  async getPortOutPasscode() {
    try {
      const portOutPasscodeData = await this.portalApi.phoneNumbersSettings.pbxs(this.globalState.selectedPbxUUID).portOutPasscode().get()
      if (portOutPasscodeData) {
        this.phoneNumberPortOutPasscode = portOutPasscodeData.portOutPasscode
      }
    } catch (err) {
      this.shouldShowPortOutPassCode = false
      console.error(this.errorService.getErrorMessage(err))
    }
  }

  async updatePortOutPasscode() {
    try {
      const payload = this.portalApi.phoneNumbersSettings.pbxs(this.globalState.selectedPbxUUID).portOutPasscode.call(this)
      payload.portOutPasscode = this.phoneNumberPortOutPasscode
      await payload.put()
    } catch (err) {
      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString('Save port-out passcode failed, please retry.')
          : errorMessage
      )
      this.ready = true
      throw err
    }
  }

  async save(form) {
    this.ready = false

    //GTACLPC-2908 Temporaly override emergency-settings on pbxservices
    this.data.sendEmergencyServiceCallAlerts = this.data.emergencyCallAlertsEnabled
    this.data.emergencyServiceCallAlertEmailAddress = this.data.emergencyAlertEmails

    this.data.useCboxStorage = this.cboxStorageSelector.value
    if (this.data.useCboxStorage) {
      this.data.callRecordingFormat = 'MP3'
    }

    this.addEmergencyEmailAddress()
    this.addCustomEmergencyNumber()

    try {
      await this.checkBeforeSave()
    } catch (err) {
      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString('Save failed, please retry.')
          : errorMessage
      )
      this.ready = true
      throw err
    }

    this.updateEmergencySettings()

    if (this.shouldShowPortOutPassCode) {
      this.updatePortOutPasscode()
    }

    try {
      await this.data.update()
    } catch (err) {
      const errorMessage = this.errorService.getErrorMessage(err)
      this.portalUtil.showErrorAlert(
        _.isEmpty(errorMessage)
          ? this.gettextCatalog.getString('Save failed, please retry.')
          : errorMessage
      )
      this.ready = true
      throw err
    }

    this.ready = true
    this.globalState.setPbxRegion(this.data.region)
    this.globalState.selectedPbx.billingGroupsEnabled = this.data.billingGroupsEnabled

    if (form) {
      form.$setPristine()
    }

    this.$rootScope.$broadcast('pbxUpdated', this.data)

    await this.handleCallerIdWarnings()

    const updatePromises = []

    if (this.editableDeviceFeatureConfigurations['network.cdp'] === 'default') {
      this.editableDeviceFeatureConfigurations['network.cdp'] = null
    }

    if (
      this.editableDeviceFeatureConfigurations['network.lldp'] === 'default'
    ) {
      this.editableDeviceFeatureConfigurations['network.lldp'] = null
    }

    if (!this.jbcEnabled) {
      this.editableDeviceFeatureConfigurations['network.jbc'] = null
    }

    const deviceFeatureCDPEnabledPromise = this.deviceFeatureService.setOrganizationFeatureConfiguration(
      'network.cdp',
      this.editableDeviceFeatureConfigurations['network.cdp'],
      this.selectedPbxUUID,
      this.editableDeviceFeatureConfigurations['network.cdp'] === null
    )
    updatePromises.push(deviceFeatureCDPEnabledPromise)

    const deviceFeatureLLDPEnabledPromise = this.deviceFeatureService.setOrganizationFeatureConfiguration(
      'network.lldp',
      this.editableDeviceFeatureConfigurations['network.lldp'],
      this.selectedPbxUUID,
      this.editableDeviceFeatureConfigurations['network.lldp'] === null
    )
    updatePromises.push(deviceFeatureLLDPEnabledPromise)

    const deviceFeatureBackgroundSyncEnabledPromise = this.deviceFeatureService.setOrganizationFeatureConfiguration(
      'polycom.ldap.backgroundSync',
      this.editableDeviceFeatureConfigurations['polycom.ldap.backgroundSync'],
      this.selectedPbxUUID,
      false
    )
    updatePromises.push(deviceFeatureBackgroundSyncEnabledPromise)

    const deviceFeatureDirectoryViewPersistEnabledPromise = this.deviceFeatureService.setOrganizationFeatureConfiguration(
      'polycom.ldap.directoryViewPersistence',
      this.editableDeviceFeatureConfigurations[
      'polycom.ldap.directoryViewPersistence'
      ],
      this.selectedPbxUUID,
      false
    )
    updatePromises.push(deviceFeatureDirectoryViewPersistEnabledPromise)

    const deviceFeatureJbcOrgDefaultPromise = this.deviceFeatureService.setOrganizationFeatureConfiguration(
      'network.jbc',
      this.editableDeviceFeatureConfigurations['network.jbc'],
      this.selectedPbxUUID,
      false
    )
    updatePromises.push(deviceFeatureJbcOrgDefaultPromise)

    let settingState = this.pstnOutboundEnabled ? 'ENABLED' : 'DISABLED'
    const pbxFeaturePSTNEnabledPromise = this.featuresService.setContextFeature(
      this.selectedPbxUUID,
      FEATURE_FLAGS.pstnOutboundEnabled,
      settingState
    )
    updatePromises.push(pbxFeaturePSTNEnabledPromise)

    return this.$q
      .all([
        deviceFeatureBackgroundSyncEnabledPromise,
        deviceFeatureDirectoryViewPersistEnabledPromise,
        deviceFeatureJbcOrgDefaultPromise
      ])
      .catch(() => {
        const revertPromises = []

        const revertCDCPromise = deviceFeatureCDPEnabledPromise.then(() =>
          this.deviceFeatureService
            .setOrganizationFeatureConfiguration(
              'network.cdp',
              this.baseEditableDeviceFeatureConfigurations['network.cdp'],
              this.selectedPbxUUID
            )
            .catch(revertError => {
              this.$log.error(
                'Failure reverting changes to network discovery protocol CDP for ' +
                this.baseData.userAgentId,
                revertError
              )
            })
        )
        revertPromises.push(revertCDCPromise)

        const revertLLDPPromise = deviceFeatureLLDPEnabledPromise.then(() =>
          this.deviceFeatureService
            .setOrganizationFeatureConfiguration(
              'network.lldp',
              this.baseEditableDeviceFeatureConfigurations['network.lldp'],
              this.selectedPbxUUID
            )
            .catch(revertError => {
              this.$log.error(
                'Failure reverting changes to network discovery protocol LLDP for ' +
                this.baseData.userAgentId,
                revertError
              )
            })
        )
        revertPromises.push(revertLLDPPromise)

        const revertBackgroundSyncPromise = deviceFeatureBackgroundSyncEnabledPromise.then(
          () =>
            this.deviceFeatureService
              .setOrganizationFeatureConfiguration(
                'polycom.ldap.backgroundSync',
                this.baseEditableDeviceFeatureConfigurations[
                'polycom.ldap.backgroundSync'
                ],
                this.selectedPbxUUID
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to background sync for ' +
                  this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertBackgroundSyncPromise)

        const revertDirectoryViewPersistPromise = deviceFeatureDirectoryViewPersistEnabledPromise.then(
          () =>
            this.deviceFeatureService
              .setOrganizationFeatureConfiguration(
                'polycom.ldap.directoryViewPersistence',
                this.baseEditableDeviceFeatureConfigurations[
                'polycom.ldap.directoryViewPersistence'
                ],
                this.selectedPbxUUID
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to directory view persistence for ' +
                  this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertDirectoryViewPersistPromise)

        const revertDefaultJbcPromise = deviceFeatureJbcOrgDefaultPromise.then(
          () =>
            this.deviceFeatureService
              .setOrganizationFeatureConfiguration(
                'network.jbc',
                this.baseEditableDeviceFeatureConfigurations['network.jbc'],
                this.selectedPbxUUID
              )
              .catch(revertError => {
                this.$log.error(
                  'Failure reverting changes to default business continuity for ' +
                  this.baseData.userAgentId,
                  revertError
                )
              })
        )
        revertPromises.push(revertDefaultJbcPromise)

        this.$q
          .all(revertPromises)
          .then(error => {
            throw error
          })
          .catch(error => {
            throw error
          })
      })
      .then(() => this.updateAppliedFeatures())
  }

  regionChangedHandler(form) {
    this.regionChanged = true

    this.shouldShowAreaCodeField = this.portalUtil.regionHasAreaCodes(this.data.region)

    if (this.shouldShowAreaCodeField) {
      if (form.defaultAreaCode) {
        let valid = this.portalUtil.validateAreaCodeOnRegionChange(
          form.defaultAreaCode.$viewValue,
          this.data.region
        )
        form.defaultAreaCode.$setTouched(true)
        form.defaultAreaCode.$setValidity('validAreaCode', valid)
        if (valid) {
          this.data.localAreaCode = form.defaultAreaCode.$viewValue
        }
      }
    } else {
      this.data.localAreaCode = ''
    }
  }

  startChangeExtensionDigit(event) {
    this.$analytics.eventTrack(
      'System Settings: Start Changing Extension Digits',
      { category: 'Change Extension Digits' }
    )
    // change the extension digit length
    this.changeExtensionDigits
      .confirm(event, this.data.extensionDigits)
      .then(
        newLength => {
          this.data.extensionDigits = newLength
          this.$rootScope.$broadcast('pbxUpdated', this.data)
        },
        () => {
          // dismissed, do nothing
        }
      )
      .catch(error => {
        this.portalUtil.showErrorAlert(
          this.gettextCatalog.getString(
            'Change extension digit failed, please retry.'
          )
        )
        throw error
      })
  }

  async validateAWSCredential() {
    try {
      const data = await this.portalApi.externalStorage
        .validateAWSCredential()
        .get({
          storageAccessKeyId: this.data.storageAccessKeyId,
          storageSecretAccessKey: this.data.storageSecretAccessKey,
          storageBucketName: this.data.storageBucketName
        })
      this.AWSCredentialValid = data === 'true'
    } catch (err) {
      this.data.busy = false
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString('Conflict checking failed, please retry.')
      )
      throw err
    }
  }

  addEmergencyEmailAddress() {
    if (this.data.newEmail) {
      this.data.emergencyAlertEmails.push(this.data.newEmail)
      this.data.newEmail = ''
    }
  }

  removeEmergencyEmailAddress(index) {
    this.data.emergencyAlertEmails.splice(index, 1)
  }

  showFaxStorageSection() {
    return this.region.faxSupported && this.virtualFaxMachineStorageEnabled
  }

  isVirtualFaxPermissionAccessible() {
    return this.globalState.isVirtualFaxMachinePermissionsEnabled || this.isStoreIncomingFaxes
  }

  async addCustomEmergencyNumber() {
    if (!this.data.newNumber) {
      return
    }
    await this.portalApi
      .customEmergencyNumbers(this.globalState.selectedPbxUUID)
      .post({ number: this.data.newNumber })
    this.data.customEmergencyNumbers.push({ number: this.data.newNumber })
    this.data.newNumber = ''
  }

  async removeCustomEmergencyNumber(index) {
    const { number } = this.data.customEmergencyNumbers[index]
    await this.portalApi
      .customEmergencyNumbers(this.globalState.selectedPbxUUID)
      .one(number)
      .remove()
    this.data.customEmergencyNumbers.splice(index, 1)
  }

  setCallParkTimeout(newDuration) {
    this.data.callParkingTimeoutInSeconds = newDuration
  }

  setMaxVoicemailDuration(newDuration) {
    this.data.maxVoicemailDuration = newDuration
  }

  openAwsPopup(storageForm) {
    let awsInfo = {
      storageAccessKeyId: this.data.storageAccessKeyId,
      storageBucketName: this.data.storageBucketName,
      hasStorageSecretAccessKey: this.data.hasStorageSecretAccessKey
    }
    this.awsSecretsPopup.open(awsInfo).then(awsUpdatedInfo => {
      this.data.hasStorageSecretAccessKey = awsUpdatedInfo.hasStorageSecretAccessKey
      this.data.storageBucketName = awsUpdatedInfo.storageBucketName
      this.data.storageAccessKeyId = awsUpdatedInfo.storageAccessKeyId
      this.$timeout(() => {
        this.triggerStorageTypeValidation(storageForm)
      })
    })
  }

  triggerStorageTypeValidation(storageForm) {
    storageForm.storageType.$validate()
  }

  async clearAwsInfo(storageForm) {
    this.data.storageAccessKeyId = ''
    this.data.storageBucketName = ''
    this.data.hasStorageSecretAccessKey = false
    this.$analytics.eventTrack('Call Recording: AWS Secrets Cleared', {
      category: 'Settings'
    })
    const payload = this.portalApi.pbx
      .pbxs(this.globalState.selectedPbxUUID)
      .one('aws-secret')
    payload.storageSecretAccessKey = ''
    payload.storageBucketName = ''
    payload.storageAccessKeyId = ''
    this.data.busy = true
    try {
      await payload.put()
      this.data.busy = false
    } catch (err) {
      this.data.busy = false
      this.$analytics.eventTrack('Call Recording: AWS Secrets Clear Failed', {
        category: 'Settings'
      })
      this.portalUtil.showErrorAlert(
        this.gettextCatalog.getString(
          'Failed to remove AWS Credentials, please retry.'
        )
      )
    }
    this.triggerStorageTypeValidation(storageForm)
  }

  async checkBeforeSave() {
    if (!this.regionChanged) {
      return Promise.resolve('no need to check')
    }

    const regionInfo = await this.portalApi.regionInfo
      .pbxs(this.globalState.selectedPbxUUID)
      .regionInfo()
      .get()

    let allReservedNumbers = []
    let allEmergencyNumbers = []
    let newRegion = true
    _.forEach(regionInfo.regionsInUse, region => {
      allReservedNumbers = _.union(allReservedNumbers, region.reservedNumbers)
      allEmergencyNumbers = _.union(
        allEmergencyNumbers,
        region.reservedNumberPrefixes
      )
      if (this.data.region === region.code) {
        newRegion = false
      }
    })

    if (newRegion) {
      const thisRegion = _.find(this.globalState.supportedRegions, {
        code: this.data.region
      })
      allReservedNumbers = _.union(
        allReservedNumbers,
        thisRegion.reservedNumbers
      )
      allEmergencyNumbers = _.union(
        allEmergencyNumbers,
        thisRegion.reservedNumberPrefixes
      )
    }

    let extensions = await this.portalApi.extension
      .pbxs(this.globalState.selectedPbxUUID)
      .extensions()
      .get({
        limit: this.LIST_LIMIT_DEFAULT
      })

    if (extensions.totalCount > this.LIST_LIMIT_DEFAULT) {
      extensions = await this.portalApi.extension
        .pbxs(this.globalState.selectedPbxUUID)
        .extensions()
        .get({
          offset: this.LIST_LIMIT_DEFAULT,
          limit: extensions.totalCount - this.LIST_LIMIT_DEFAULT
        })
    }

    let extensionsWithReservedNumbers = []
    let displayString = ' '
    _.forEach(extensions.items, extension => {
      if (_.indexOf(allReservedNumbers, extension.extensionNumber) > -1) {
        extensionsWithReservedNumbers.push(extension)
        displayString += extension.extensionNumber + ' '
      }

      // Match emergency numbers using startsWith
      _.forEach(allEmergencyNumbers, emergencyNumber => {
        if (
          extension.extensionNumber &&
          extension.extensionNumber.startsWith(emergencyNumber)
        ) {
          extensionsWithReservedNumbers.push(extension)
          displayString += extension.extensionNumber + ' '
        }
      })
    })

    if (extensionsWithReservedNumbers.length > 0) {
      await this.portalUtil.showAlert(
        this.gettextCatalog.getString('Warning'),
        this.gettextCatalog.getString(
          'The following extensions are reserved numbers in the new region. Please correct before proceeding:'
        ) +
        '\n' +
        displayString,
        true
      )
      return Promise.reject({ message: 'reserved numbers found.' })
    }

    return Promise.resolve({ message: 'no reserved numbers found' })
  }

  async updateSupportedOrganizationFeatures() {
    const response = await this.deviceFeatureService.getOrganizationAppliedFeatureConfiguration(
      this.selectedPbxUUID,
      null
    )
    if (response.items) {
      _.forEach(response.items, feature => {
        this.supportedDeviceFeatures[feature.key] = feature
      })
    }
  }

  async updatePSTNOutboundFeatures() {
    const setting = await this.featuresService
      .getContextFeature(this.selectedPbxUUID, FEATURE_FLAGS.pstnOutboundEnabled)
      .then(featureFlag => {
        return featureFlag.setting;
      });

    if (setting.length === 0) {
      return
    }

    const isFeatureEnabled = setting === 'ENABLED';
    this.pstnOutboundEnabled = isFeatureEnabled
  }

  async updateAppliedFeatures() {
    const response = await this.deviceFeatureService.getOrganizationAppliedFeatureConfiguration(
      this.selectedPbxUUID,
      null
    )
    if (response.featureConfigurations.length === 0) {
      return
    }

    _.forEach(response.featureConfigurations, feature => {
      const deviceFeature = feature.deviceFeatureKey
      this.appliedFeatureConfigurations[deviceFeature] = feature
      this.editableDeviceFeatureConfigurations[deviceFeature] = feature.value
    })

    this.baseEditableDeviceFeatureConfigurations = _.cloneDeep(
      this.editableDeviceFeatureConfigurations
    )

    if (
      this.editableDeviceFeatureConfigurations['network.cdp'] !== null &&
      !this.editableDeviceFeatureConfigurations['network.cdp']
    ) {
      this.editableDeviceFeatureConfigurations['network.cdp'] = 'default'
    }

    if (
      this.editableDeviceFeatureConfigurations['network.lldp'] !== null &&
      !this.editableDeviceFeatureConfigurations['network.lldp']
    ) {
      this.editableDeviceFeatureConfigurations['network.lldp'] = 'default'
    }

    this.jbcEnabled =
      this.editableDeviceFeatureConfigurations['network.jbc'] !== null

    this.isStoreIncomingFaxes = this.globalState.selectedPbx.storeIncomingFaxes
  }

  shouldShowDisableGtcMeetingToggle() {
    return !this.isWhiteLabel &&
      !!this.globalState.selectedPbx.licenses &&
      !!this.globalState.selectedPbx.licenses.find(license =>
        license.roles &&
        license.roles.find(role => role === 'ROLE_GOTOCONNECT_ORGANIZER'))
  }
}

_module.controller('SettingsController', SettingsController)
